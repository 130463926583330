import { Injectable } from '@angular/core';
import type { FinishedWorkOrder, WorkOrder } from '@tag/graphql';
import {
  BatchWorkOrderEdit,
  ReportWOMaintenance,
  WorkOrderFromTemplatePost,
  WorkOrderUpsert,
} from '@api/types';
import { Operation } from 'fast-json-patch';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FinishedWorkOrdersGQL } from '@shared/apollo/queries/finished-work-order';
import {
  WorkOrdersGQL,
  PlannedWorkOrdersGQL,
} from '@shared/apollo/queries/work-order';
import { Paginated } from '@stores-models/paginated';
import { ApiService } from '@services/api.service';

/**
 * Service used to automate CRUD operation from the NGXS store to TAG API V2.
 *
 * @deprecated This is an internal implementation class, do not use directly.
 */
@Injectable({
  providedIn: 'root',
})
export class WorkOrderStoreService {
  constructor(
    private workOrdersGQL: WorkOrdersGQL,
    private api: ApiService,
    private finishedWorkOrdersGQL: FinishedWorkOrdersGQL,
    private plannedWorkOrdersGQL: PlannedWorkOrdersGQL
  ) {}

  /**
   * Fetchs work order
   *
   * @param filter OData filter
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchWorkOrders(filter?: string): Observable<WorkOrder[]> {
    return this.workOrdersGQL
      .watch({ filter })
      .valueChanges.pipe(map((res) => res.data.workOrders.items));
  }

  /**
   * Fetchs work order
   *
   * @param filter OData filter
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPaginatedWorkOrders(
    top: number,
    skip: number,
    filter?: string,
    orderBy?: string,
    desc = false
  ): Observable<Paginated<WorkOrder>> {
    return this.workOrdersGQL
      .watch({ filter, top, skip, orderBy, desc })
      .valueChanges.pipe(
        map((res) => ({
          items: res.data.workOrders.items,
          totalCount: res.data.workOrders.totalCount,
          skip,
          top,
          filter,
          custom: 'released',
        }))
      );
  }

  /**
   * Fetchs work order
   *
   * @param filter OData filter
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPaginatedFinishedWorkOrders(
    top: number,
    skip: number,
    filter?: string,
    orderBy?: string,
    desc = false
  ): Observable<Paginated<FinishedWorkOrder>> {
    return this.finishedWorkOrdersGQL
      .watch({ filter, top, skip, orderBy, desc })
      .valueChanges.pipe(
        map((res) => ({
          items: res.data.finishedWorkOrders.items,
          totalCount: res.data.finishedWorkOrders.totalCount,
          skip,
          top,
          filter,
          custom: 'finished',
        }))
      );
  }

  /**
   * Fetchs work order
   *
   * @param filter OData filter
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPaginatedPlannedWorkOrders(
    top: number,
    skip: number,
    filter?: string,
    orderBy?: string,
    desc = false
  ): Observable<Paginated<WorkOrder>> {
    return this.plannedWorkOrdersGQL
      .watch({ filter, top, skip, orderBy, desc })
      .valueChanges.pipe(
        map((res) => ({
          items: res.data.plannedWorkOrders.items,
          totalCount: res.data.plannedWorkOrders.totalCount,
          skip,
          top,
          filter,
          custom: 'planned',
        }))
      );
  }

  /**
   * Fetchs work order
   *
   * @param no Work Order unique ID
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchWorkOrder(no: string): Observable<WorkOrder | undefined> {
    const filter = `No eq '${no}'`;
    return this.workOrdersGQL
      .watch({ filter })
      .valueChanges.pipe(map((res) => res.data.workOrders.items[0]));
  }

  /**
   * Fetchs work order
   *
   * @param no Work Order unique ID
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchFinishedWorkOrder(
    no: string
  ): Observable<FinishedWorkOrder | undefined> {
    const filter = `No eq '${no}'`;
    return this.finishedWorkOrdersGQL
      .watch({ filter })
      .valueChanges.pipe(map((res) => res.data.finishedWorkOrders.items[0]));
  }

  /**
   * Fetchs work order
   *
   * @param no Work Order unique ID
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  fetchPlannedWorkOrder(no: string): Observable<WorkOrder | undefined> {
    const filter = `No eq '${no}'`;
    return this.plannedWorkOrdersGQL
      .watch({ filter })
      .valueChanges.pipe(map((res) => res.data.plannedWorkOrders.items[0]));
  }

  /**
   * Deletes work order
   *
   * @param no
   * @returns Mostly nothing.
   * @deprecated This is an internal implementation method, do not use directly.
   */
  deleteWorkOrder(no: string): Observable<any> {
    return this.api.delete(`/work-orders/Released/${no}`);
  }

  /**
   * Upserts work order
   *
   * @param payload
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  upsertWorkOrder(payload: WorkOrderUpsert): Observable<WorkOrder> {
    return this.api.post('/work-orders', payload);
  }

  /**
   * Adds work order from template
   *
   * @param payload
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addWorkOrderFromTemplate(
    payload: WorkOrderFromTemplatePost
  ): Observable<{ no: string }> {
    return this.api.post('/work-orders/template', payload);
  }

  /**
   * Adds work order from request
   *
   * @param payload Work Order From Request Post object
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  addWorkOrderFromRequest(payload: any): Observable<WorkOrder> {
    throw new Error('Method not implemented.');
  }

  /**
   * Updates work order
   *
   * @param no
   * @param patch
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateWorkOrder(no: string, patch: Operation[]): Observable<WorkOrder> {
    throw new Error('Method not implemented.');
  }

  /**
   * Updates work order
   *
   * @param no
   * @param patch
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updatePlannedWorkOrder(
    no: string,
    patch: Operation[]
  ): Observable<WorkOrder> {
    throw new Error('Method not implemented.');
  }
  /**
   * Updates list of work orders
   *
   * @param workOrderBatch
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateWorkOrders(
    workOrderBatch: BatchWorkOrderEdit
  ): Observable<WorkOrder[]> {
    return this.api.post('/work-orders/batch', workOrderBatch);
  }

  /**
   * Updates a work order universal document No
   *
   * @param woNo
   * @param udnNo
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateWorkOrderUniversalDocumentNo(
    woNo: string,
    udnNo: string
  ): Observable<any> {
    return this.api.post('/work-orders/Released/' + woNo + '/update-udn', {
      udn: udnNo,
    });
  }

  /**
   * Updates work order
   *
   * @param no
   *
   * @returns work order
   * @deprecated This is an internal implementation method, do not use directly.
   */
  completeWorkOrder(no: string): Observable<string> {
    throw new Error('Method not implemented.');
  }

  /**
   * Batch Review work orders
   *
   * @param payload Batch WorkOrder Review Post object
   *
   * @returns string
   * @deprecated This is an internal implementation method, do not use directly.
   */
  reviewWorkOrders(payload: any): Observable<any> {
    return this.api.post('/work-orders/review', payload);
  }

  /**
   * Batch Report work order
   *
   * @param payload Batch Report Work Order Post object
   *
   * @returns Work Order Post Object
   * @deprecated This is an internal implementation method, do not use directly.
   */
  reportWorkOrder(payload: ReportWOMaintenance): Observable<any> {
    return this.api.post(
      '/work-orders/' +
        payload.document_Type +
        '/' +
        payload.work_Order_No +
        '/report-maintenance',
      payload
    );
  }

  /**
   * Updates work order type
   *
   * @param no
   * @param type
   * @param company
   * @returns work order type
   * @deprecated This is an internal implementation method, do not use directly.
   */
  updateWorkOrderType(no: string, type: any): Observable<string> {
    return this.api.post('/work-orders/change-type', { no, type });
  }
}
