import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
  Action,
  Selector,
  State,
  StateContext,
  Store,
  createSelector,
} from '@ngxs/store';
import {
  FinishedWorkOrder,
  PostedTimesheet,
  WorkOrder,
  WorkOrderLine,
} from '@tag/graphql';
import { Apollo } from 'apollo-angular';
import produce from 'immer';

import { SetActiveCard } from '@cards-stores/card.actions';
import { CardOptions } from '@cards-utils/card-options.enum';
import { ApolloService } from '@shared/apollo/apollo.service';
import {
  AddI18nNotification,
  AddNotification,
} from '@stores-actions/notification.action';
import {
  AddWorkOrder,
  AddWorkOrderFromRequest,
  AddWorkOrderFromTemplate,
  CompleteWorkOrder,
  DeleteWorkOrder,
  GetPaginatedWorkOrders,
  GetSummaryWorkOrders,
  GetWorkOrder,
  GetWorkOrders,
  ReportWorkOrder,
  ReviewWorkOrders,
  SetSelectedWorkOrder,
  SetSelectedWorkOrders,
  UpdateWorkOrder,
  UpdateWorkOrderType,
  UpdateWorkOrderUniversalDocumentNo,
  UpdateWorkOrders,
  UpsertWorkOrder,
} from '@stores-actions/work-order.action';
import { Paginated } from '@stores-models/paginated';
import { TimeSheet } from '@stores-models/timesheet';
import { WorkOrderStoreService } from '@stores-services/work-order-store.service';

import { FeedbackStateObject } from './feedback.state';
import { RequirementStateObject } from './requirement.state';
import {
  ErrorAndFinishedWorkOrderResult,
  ReportWOMaintenance,
  ReportWOMaintenanceReturn,
  WorkOrderDocumentType,
} from '@api/types';

import { convertDateTimeLocalToOnlyDateUTCforBC } from '@helpers/utils/date.utils';
import { MaintenanceService } from '@forms-services/report-maintenance.service';
import { AuthState } from './authentication.state';
import { Observable, switchMap, tap, take, of, map } from 'rxjs';
import { RequirementsGQL } from '@shared/apollo/queries/requirement';

interface WorkOrderStateObject extends WorkOrder {
  type: 'released' | 'planned' | 'finished';
}

export class WorkOrderStateModel {
  workOrders: WorkOrderStateObject[] = [];
  paginatedWorkOrders: Paginated<WorkOrder>[] = [];
  selectedWorkOrder!: WorkOrder | null;
  selectedWorkOrders: WorkOrder[] = [];
  initialized = false;
}

/**
 * Work Order Lines metadata and action mappings.
 */
@State<WorkOrderStateModel>({
  name: 'workOrder',
  defaults: {
    workOrders: [],
    paginatedWorkOrders: [],
    selectedWorkOrder: null,
    selectedWorkOrders: [],
    initialized: false,
  },
})
@Injectable()
export class WorkOrderState {
  constructor(
    private workOrderStoreService: WorkOrderStoreService,
    private requirementsGQL: RequirementsGQL,
    private maintenanceService: MaintenanceService,
    private store: Store,
    private translocoService: TranslocoService,
    private apollo: Apollo,
    private apolloUtils: ApolloService
  ) {}

  static getWorkOrdersByArea(
    no: string
  ): (state: WorkOrderStateModel) => WorkOrderStateObject[] {
    return createSelector([WorkOrderState], (state: WorkOrderStateModel) =>
      state.workOrders.filter((wo) => wo.area === no)
    );
  }

  static getWorkOrdersByFacility(
    no: string
  ): (state: WorkOrderStateModel) => WorkOrderStateObject[] {
    return createSelector([WorkOrderState], (state: WorkOrderStateModel) =>
      state.workOrders.filter((wo) => wo.facility === no)
    );
  }

  static getWorkOrdersByTechnician(
    code: string
  ): (state: WorkOrderStateModel) => WorkOrderStateObject[] {
    return createSelector([WorkOrderState], (state: WorkOrderStateModel) =>
      state.workOrders.filter((wo) => wo.technicianCode === code)
    );
  }

  static getWorkOrder(
    no: string,
    woType: 'released' | 'planned' | 'finished' = 'released'
  ): (state: WorkOrderStateModel) => WorkOrderStateObject | undefined {
    return createSelector([WorkOrderState], (state: WorkOrderStateModel) =>
      state.workOrders.find((wo) => wo.no === no && wo.type === woType)
    );
  }

  static getWorkOrdersByPagination(
    type: 'released' | 'planned' | 'finished',
    top: number,
    skip: number,
    filter?: string
  ): (state: WorkOrderStateModel) => Paginated<WorkOrder> | undefined {
    return createSelector([WorkOrderState], (state: WorkOrderStateModel) =>
      state.paginatedWorkOrders.find(
        (wo) =>
          wo.top === top &&
          wo.skip === skip &&
          wo.filter === filter &&
          wo.custom === type
      )
    );
  }

  @Selector()
  static getWorkOrders(state: WorkOrderStateModel): WorkOrderStateObject[] {
    return state.workOrders.filter((wo) => !wo.approvalPending);
  }

  @Selector()
  static getSelectedWorkOrder(state: WorkOrderStateModel): WorkOrder | null {
    return state.selectedWorkOrder;
  }

  @Selector()
  static getSelectedWorkOrders(state: WorkOrderStateModel): WorkOrder[] {
    return state.selectedWorkOrders;
  }

  @Selector()
  static getReviewWorkOrders(
    state: WorkOrderStateModel
  ): WorkOrderStateObject[] {
    return state.workOrders.filter((s) => s.approvalPending === true);
  }

  @Selector()
  static getUnassignedWorkOrders(
    state: WorkOrderStateModel
  ): WorkOrderStateObject[] {
    return state.workOrders.filter((wo) => !wo.technicianCode);
  }

  @Selector()
  static getInitStatus(state: WorkOrderStateModel): boolean {
    return state.initialized;
  }

  @Action(GetWorkOrders, { cancelUncompleted: true })
  getWorkOrders(
    { patchState }: StateContext<WorkOrderStateModel>,
    { filter }: GetWorkOrders
  ): Observable<WorkOrder[]> {
    return this.workOrderStoreService.fetchWorkOrders(filter).pipe(
      tap((result) => {
        patchState({
          workOrders: result.map((wo) => ({ ...wo, type: 'released' })),
        });
      })
    );
  }

  @Action(GetSummaryWorkOrders, { cancelUncompleted: true })
  getSummaryWorkOrders(
    { patchState }: StateContext<WorkOrderStateModel>,
    { filter, bustCache }: GetWorkOrders
  ): Observable<WorkOrder[]> {
    return this.workOrderStoreService.fetchWorkOrders(filter).pipe(
      tap((result) => {
        patchState({
          workOrders: result.map((wo) => ({ ...wo, type: 'released' })),
        });
      })
    );
  }

  @Action(GetWorkOrder, { cancelUncompleted: true })
  getWorkOrder(
    ctx: StateContext<WorkOrderStateModel>,
    { no, woType }: GetWorkOrder
  ): Observable<WorkOrder | FinishedWorkOrder | undefined> {
    let obs$: Observable<WorkOrder | FinishedWorkOrder | undefined>;

    switch (woType) {
      case 'planned':
        obs$ = this.workOrderStoreService.fetchPlannedWorkOrder(no);
        break;
      case 'finished':
        obs$ = this.workOrderStoreService.fetchFinishedWorkOrder(no);
        break;
      default:
        obs$ = this.workOrderStoreService.fetchWorkOrder(no);
        break;
    }

    return obs$.pipe(
      tap((result) => {
        if (!result) return;

        const workOrder = {
          ...result,
          type: woType,
          documentType: woType,
        };

        ctx.setState(
          produce((draft: WorkOrderStateModel) => {
            draft.workOrders = draft.workOrders.filter((wo) => wo.no !== no);
            draft.workOrders.push(workOrder as any);
          })
        );
      })
    );
  }

  @Action(GetPaginatedWorkOrders, { cancelUncompleted: true })
  getPaginatedWorkOrders(
    ctx: StateContext<WorkOrderStateModel>,
    { woType, filter, top, skip, orderBy, desc }: GetPaginatedWorkOrders
  ): Observable<Paginated<any>> {
    let obs$: Observable<Paginated<any>>;

    switch (woType) {
      case 'planned':
        obs$ = this.workOrderStoreService.fetchPaginatedPlannedWorkOrders(
          top,
          skip,
          filter,
          orderBy,
          desc
        );
        break;
      case 'finished':
        obs$ = this.workOrderStoreService.fetchPaginatedFinishedWorkOrders(
          top,
          skip,
          filter,
          orderBy,
          desc
        );
        break;

      default:
        obs$ = this.workOrderStoreService.fetchPaginatedWorkOrders(
          top,
          skip,
          filter,
          orderBy,
          desc
        );
        break;
    }

    return obs$.pipe(
      tap((result) => {
        ctx.setState(
          produce((draft: WorkOrderStateModel) => {
            draft.paginatedWorkOrders = draft.paginatedWorkOrders.filter(
              (wo) =>
                wo.top !== top &&
                wo.skip !== skip &&
                wo.filter !== filter &&
                wo.custom !== woType
            );
            draft.paginatedWorkOrders.push(result);
          })
        );
      })
    );
  }

  @Action(UpsertWorkOrder)
  upsertWorkOrder(
    ctx: StateContext<WorkOrderStateModel>,
    { payload }: UpsertWorkOrder
  ): Observable<WorkOrder> {
    if (payload.order_Date) {
      payload.order_Date = convertDateTimeLocalToOnlyDateUTCforBC(
        payload.order_Date
      );
    }

    if (payload.due_By_Date) {
      payload.due_By_Date = convertDateTimeLocalToOnlyDateUTCforBC(
        payload.due_By_Date
      );
    }

    if (payload.requested_Service_Date) {
      payload.requested_Service_Date = convertDateTimeLocalToOnlyDateUTCforBC(
        payload.requested_Service_Date
      );
    }
    if (payload.expiration_Date) {
      payload.expiration_Date = convertDateTimeLocalToOnlyDateUTCforBC(
        payload.expiration_Date
      );
    }

    return this.workOrderStoreService.upsertWorkOrder(payload).pipe(
      tap((result) => {
        let message = this.translocoService.translate(
          'notificationsMessagesKeys.workOrderNoWasCreatedSuccessfullyKeyParam',
          {
            id: result.no,
          }
        );
        let title = this.translocoService.translate('createKey');

        if (payload.no) {
          message = this.translocoService.translate(
            'notificationsMessagesKeys.workOrderNoWasUpdatedSuccessfullyKeyParam',
            {
              id: result.no,
            }
          );
          title = this.translocoService.translate('updatedKey');
        }

        if (result.no) {
          ctx.dispatch([new AddNotification(message, 'success', title)]);
        }

        ctx.patchState({
          selectedWorkOrder: result,
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrders',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrderLines',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'requirements',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'feedbacks',
        });
      })
    );
  }

  @Action(AddWorkOrderFromTemplate)
  addWorkOrderFromTemplate(
    ctx: StateContext<WorkOrderStateModel>,
    { payload }: AddWorkOrderFromTemplate
  ): Observable<{ no: string }> {
    return this.workOrderStoreService.addWorkOrderFromTemplate(payload).pipe(
      tap((result) => {
        const message = this.translocoService.translate(
          'notificationsMessagesKeys.workOrderNoWasCreatedSuccessfullyKeyParam',
          {
            id: result.no,
          }
        );
        const title = this.translocoService.translate('createKey');

        ctx.patchState({
          selectedWorkOrder: result as any,
        });

        if (result.no) {
          this.store.dispatch([new AddNotification(message, 'success', title)]);
        }

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrders',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrderLines',
        });
      })
    );
  }

  @Action(AddWorkOrderFromRequest)
  addWorkOrderFromRequest(
    ctx: StateContext<WorkOrderStateModel>,
    { payload }: AddWorkOrderFromRequest
  ): Observable<WorkOrder> {
    return this.workOrderStoreService.addWorkOrderFromRequest(payload).pipe(
      tap((result) => {
        const message = this.translocoService.translate(
          'notificationsMessagesKeys.requestNoWasConvertedSuccessfullykeyParam',
          {
            id: payload.request_No,
            idWorkOrder: result.no,
          }
        );
        const title = this.translocoService.translate('postingKey');
        this.store.dispatch(new AddNotification(message, 'success', title));

        ctx.patchState({
          selectedWorkOrder: result,
        });

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrders',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrderLines',
        });

        this.apolloUtils.pessimisticCacheUpdate(
          'requests',
          payload.request_No || ''
        );
      })
    );
  }

  @Action(UpdateWorkOrder)
  updateWorkOrder(
    ctx: StateContext<WorkOrderStateModel>,
    { patch, no, type }: UpdateWorkOrder
  ): Observable<WorkOrder> {
    const udpateObs$ =
      type === 'Planned'
        ? this.workOrderStoreService.updatePlannedWorkOrder(no, patch)
        : this.workOrderStoreService.updateWorkOrder(no, patch);

    return udpateObs$.pipe(
      tap((result) => {
        const message = this.translocoService.translate(
          'notificationsMessagesKeys.workOrderNoWasUpdatedSuccessfullyKeyParam',
          { id: no }
        );
        const title = this.translocoService.translate('updatedKey');

        this.store.dispatch(new AddNotification(message, 'success', title));

        ctx.patchState({
          selectedWorkOrder: result,
        });

        this.apolloUtils.pessimisticCustomCacheUpdate<WorkOrder>(
          'plannedWorkOrders',
          {
            no,
          }
        );

        this.apolloUtils.pessimisticCustomCacheUpdate<WorkOrder>('workOrders', {
          no,
        });
      })
    );
  }

  @Action(UpdateWorkOrders)
  updateWorkOrders(
    ctx: StateContext<WorkOrderStateModel>,
    { workOrderBatch }: UpdateWorkOrders
  ): Observable<WorkOrder[]> {
    return this.workOrderStoreService.updateWorkOrders(workOrderBatch).pipe(
      map((results: WorkOrder | WorkOrder[]): WorkOrder[] => {
        const resultsArray = Array.isArray(results) ? results : [results];

        const updatedWorkOrderIds: string[] = resultsArray
          .filter((result) => result.no)
          .map((result) => result.no as string);

        if (updatedWorkOrderIds.length > 0) {
          const message = this.translocoService.translate(
            'notificationsMessagesKeys.workOrderNoWasUpdatedSuccessfullyKeyParam',
            {
              id: updatedWorkOrderIds.join(','),
            }
          );
          const title = this.translocoService.translate('updatedKey');
          this.store.dispatch(new AddNotification(message, 'success', title));

          ctx.patchState({
            selectedWorkOrder: resultsArray[0],
          });

          this.apolloUtils.pessimisticCustomCacheBatchUpdate<WorkOrder>(
            'workOrders',
            updatedWorkOrderIds.map((id) => ({ no: id }))
          );
          this.apolloUtils.pessimisticCustomCacheBatchUpdate<FeedbackStateObject>(
            'feedbacks',
            updatedWorkOrderIds.map((id) => ({ documentNo: id }))
          );
          this.apolloUtils.pessimisticCustomCacheBatchUpdate<RequirementStateObject>(
            'requirements',
            updatedWorkOrderIds.map((id) => ({ sourceNo: id }))
          );
        }

        return resultsArray;
      })
    );
  }

  @Action(UpdateWorkOrderUniversalDocumentNo)
  updateWorkOrderUniversalDocumentNo(
    ctx: StateContext<WorkOrderStateModel>,
    { woNo, udnNo }: UpdateWorkOrderUniversalDocumentNo
  ): Observable<string> {
    return this.workOrderStoreService
      .updateWorkOrderUniversalDocumentNo(woNo, udnNo)
      .pipe(
        tap(() => {
          const message = this.translocoService.translate(
            'notificationsMessagesKeys.workOrderNoWasUpdatedSuccessfullyKeyParam',
            {
              id: woNo,
            }
          );
          const title = this.translocoService.translate('updatedKey');

          this.store.dispatch([new AddNotification(message, 'success', title)]);

          this.apolloUtils.pessimisticCustomCacheUpdate<WorkOrder>(
            'workOrders',
            {
              no: woNo,
            }
          );
        })
      );
  }

  @Action(CompleteWorkOrder)
  completeWorkOrder(
    ctx: StateContext<WorkOrderStateModel>,
    { no }: CompleteWorkOrder
  ): Observable<string> {
    return this.workOrderStoreService.completeWorkOrder(no).pipe(
      tap(() => {
        const message = this.translocoService.translate(
          'notificationsMessagesKeys.workOrderNoWasCompletedSuccessfullyKeyParam',
          {
            id: no,
          }
        );
        const title = this.translocoService.translate('completedKey');

        this.store.dispatch([new AddNotification(message, 'success', title)]);

        this.apolloUtils.pessimisticCustomCacheUpdate<WorkOrder>('workOrders', {
          no,
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'finishedWorkOrders',
        });
      })
    );
  }

  @Action(ReportWorkOrder)
  reportWorkOrder(
    { getState, setState, dispatch }: StateContext<WorkOrderStateModel>,
    { payload, attachments }: ReportWorkOrder
  ) {
    return this.workOrderStoreService.reportWorkOrder(payload).pipe(
      switchMap((result: ReportWOMaintenanceReturn) => {
        let message = this.translocoService.translate(
          'theMaintenanceReportWasSuccessfullyUploadedKeyParam',
          {
            workOrder: payload.work_Order_No,
          }
        );
        const title = this.translocoService.translate(
          'workOrderReportMaintenanceKey'
        );

        // TODO - GraphQL Refactor when the requirement and feedback are refactored
        this.apolloUtils.pessimisticCustomCacheUpdate<WorkOrder>('workOrders', {
          no: payload.work_Order_No,
        });
        this.apolloUtils.pessimisticCustomCacheBatchUpdate<WorkOrderLine>(
          'workOrderLines',
          result.workOrderLines?.map((line) => ({
            workOrderNo: line.workOrderNo,
            lineNo: line.lineNo,
          })) || []
        );
        this.apolloUtils.pessimisticCustomCacheUpdate<RequirementStateObject>(
          'requirements',
          {
            sourceNo: payload.work_Order_No,
          }
        );
        this.apolloUtils.pessimisticCustomCacheUpdate<FeedbackStateObject>(
          'feedbacks',
          {
            documentNo: payload.work_Order_No,
          }
        );
        this.apolloUtils.pessimisticCustomCacheUpdate<TimeSheet>('timesheets', {
          sourceWorkOrderNo: payload.work_Order_No,
        });
        this.apolloUtils.pessimisticCustomCacheUpdate<PostedTimesheet>(
          'postedTimesheets',
          {
            originalRoNo: payload.work_Order_No,
          }
        );

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'finishedWorkOrders',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'finishedWorkOrderLines',
        });

        if (payload.complete) {
          // delete work order from local store
          const state = getState();
          const filteredArray = state.workOrders.filter(
            (item) => item.no !== payload.work_Order_No
          );
          setState({
            ...state,
            workOrders: filteredArray,
            selectedWorkOrder: null,
          });

          const finishedWorkOrderNo = result.finishedWorkOrderNo;
          if (finishedWorkOrderNo) {
            message += this.translocoService.translate(
              'notificationsMessagesKeys.finishedWorkOrderNoWasCreatedSuccessfullyKeyParam',
              {
                id: finishedWorkOrderNo,
              }
            );
            return this.workOrderStoreService
              .fetchFinishedWorkOrder(finishedWorkOrderNo)
              .pipe(
                take(1),
                switchMap((finishedWorkOrder) => {
                  dispatch(
                    new SetActiveCard(
                      'default',
                      CardOptions.finishedWorkOrder,
                      finishedWorkOrder || { no: finishedWorkOrderNo }
                    )
                  );
                  dispatch(new AddNotification(message, 'success', title));
                  return this.maintenanceService.addAttachments(
                    attachments.map((att) => ({
                      ...att,
                      documentNo: finishedWorkOrderNo,
                    })),
                    this.store.selectSnapshot(AuthState.isDocStore)
                  );
                })
              );
          } else {
            dispatch(
              new SetActiveCard('default', CardOptions.finishedWorkOrder, null)
            );
            return this.maintenanceService.addAttachments(
              attachments,
              this.store.selectSnapshot(AuthState.isDocStore)
            );
          }
        }

        dispatch(new AddNotification(message, 'success', title));
        return this.maintenanceService.addAttachments(
          attachments,
          this.store.selectSnapshot(AuthState.isDocStore)
        );
      })
    );
  }

  @Action(ReviewWorkOrders)
  reviewWorkOrders(
    ctx: StateContext<WorkOrderStateModel>,
    { payload }: ReviewWorkOrders
  ): Observable<ErrorAndFinishedWorkOrderResult> {
    const title = this.translocoService.translate('reviewKey');
    return this.workOrderStoreService.reviewWorkOrders(payload).pipe(
      tap((results: ErrorAndFinishedWorkOrderResult) => {
        const successReviewedWorkOrderIds: string[] = [];
        const failReviewedWorkOrderIds: { no: string; message: string }[] = [];

        if (results?.workOrders) {
          results.workOrders.forEach((x) => {
            if (x.workOrderNo) {
              !x.postingErrorText
                ? successReviewedWorkOrderIds.push(x.workOrderNo)
                : failReviewedWorkOrderIds.push({
                    no: x.workOrderNo,
                    message: x.postingErrorText,
                  });
            }
          });
          if (successReviewedWorkOrderIds.length > 0) {
            const message = this.translocoService.translate(
              'notificationsMessagesKeys.workOrderNoWasReviewedSuccessfullyKeyParam',
              {
                id: successReviewedWorkOrderIds.join(','),
              }
            );
            this.store.dispatch([
              new AddNotification(message, 'success', title),
            ]);
          }
        }

        this.apolloUtils.pessimisticCustomCacheBatchUpdate<WorkOrder>(
          'workOrders',
          successReviewedWorkOrderIds.map((id) => ({ no: id }))
        );

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'finishedWorkOrders',
        });
        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'finishedWorkOrderLines',
        });

        if (failReviewedWorkOrderIds.length > 0) {
          let errorMessage = '';
          if (failReviewedWorkOrderIds.length > 1) {
            errorMessage = this.translocoService.translate(
              'notificationsMessagesKeys.workOrdersReviewErrorKeyParam',
              {
                id: failReviewedWorkOrderIds.map((x) => x.no).join(', '),
              }
            );
          } else {
            errorMessage =
              failReviewedWorkOrderIds.map((x) => x.no).join(', ') +
              ' ' +
              failReviewedWorkOrderIds.map((x) => x.message).join(', ');
          }

          this.store.dispatch(
            new AddNotification(errorMessage, 'warning', title)
          );
          throw new Error(errorMessage);
        }
      })
    );
  }

  @Action(DeleteWorkOrder)
  deleteWorkOrder(
    ctx: StateContext<WorkOrderStateModel>,
    { no }: DeleteWorkOrder
  ): any {
    return this.requirementsGQL.fetch({ filter: `Source_No eq '${no}'` }).pipe(
      map((result: any) => {
        return result.data.requirements.items as RequirementStateObject[];
      }),
      switchMap((requirements: RequirementStateObject[]) => {
        const hasPurchaseOrders = requirements.some(
          (req: RequirementStateObject) => (req.requisitionedQty || 0) >= 1
        );

        if (hasPurchaseOrders) {
          this.store.dispatch(
            new AddNotification(
              'Cannot Delete WorkOrder Since Its Associated With a PurchaseOrder',
              'error',
              'Deletion Not Allowed Key'
            )
          );
          return of(false);
        }

        return this.workOrderStoreService.deleteWorkOrder(no);
      }),
      tap(() => {
        const message = this.translocoService.translate(
          'notificationsMessagesKeys.workOrderNoWasDeletedSuccessfullyKeyParam',
          { id: no }
        );
        const title = this.translocoService.translate('deletedKey');

        this.store.dispatch(new AddNotification(message, 'success', title));

        this.apollo.client.cache.evict({
          id: 'ROOT_QUERY',
          fieldName: 'workOrders',
        });
      })
    );
  }

  @Action(UpdateWorkOrderType)
  updateWorkOrderType(
    ctx: StateContext<WorkOrderStateModel>,
    { workOrder }: UpdateWorkOrderType
  ): Observable<string> {
    if (!workOrder.documentType || !workOrder.no) {
      throw new Error('Document type is required');
    }

    const documentType =
      workOrder.documentType === 'Released'
        ? WorkOrderDocumentType.released
        : WorkOrderDocumentType.planned;

    return this.workOrderStoreService
      .updateWorkOrderType(workOrder.no, documentType)
      .pipe(
        tap((result) => {
          if (result === workOrder.no) {
            this.store.dispatch(
              new AddI18nNotification(
                {
                  key: 'thereWasAnErrorWhileUpdatingWorkOrderTypeKey',
                },
                'error'
              )
            );
            return;
          }

          const message = this.translocoService.translate(
            'notificationsMessagesKeys.workOrderNoWasUpdatedSuccessfullyKeyParam',
            {
              id: result,
            }
          );
          const title = this.translocoService.translate('updatedKey');

          this.store.dispatch(new AddNotification(message, 'success', title));

          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'workOrders',
          });
          this.apollo.client.cache.evict({
            id: 'ROOT_QUERY',
            fieldName: 'plannedWorkOrders',
          });

          const newWorkOrderType =
            documentType === WorkOrderDocumentType.released
              ? 'Planned'
              : 'Released';
          this.store.dispatch(
            new SetActiveCard(result, CardOptions.workOrder, {
              ...workOrder,
              no: result,
              documentType: newWorkOrderType,
            })
          );
        })
      );
  }

  @Action(SetSelectedWorkOrders)
  setSelectedWorkOrders(
    { getState, setState }: StateContext<WorkOrderStateModel>,
    { payload }: SetSelectedWorkOrders
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedWorkOrders: payload,
    });
  }

  @Action(SetSelectedWorkOrder)
  setSelectedWorkOrder(
    { getState, setState }: StateContext<WorkOrderStateModel>,
    { payload }: SetSelectedWorkOrder
  ): void {
    const state = getState();
    setState({
      ...state,
      selectedWorkOrder: payload,
    });
  }
}
